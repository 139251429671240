import styles from "../css/app.css";

// App main
const main = async () => {
  // Import our CSS
  //const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.css');
  // Async load the vue module
  const Vue = await import(/* webpackChunkName: "vue" */ "vue");
  // Create our vue instance
  const vm = new Vue.default({
    el: "#app",
    components: {
      vimeoplayer: () =>
        import(/* webpackChunkName: "vimeoplayer" */ "../vue/VimeoPlayer.vue"),
      articlegrid: () =>
        import(/* webpackChunkName: "articlegrid" */ "../vue/ArticleGrid.vue"),
      search: () =>
        import(/* webpackChunkName: "search" */ "../vue/Search.vue"),
      artists: () =>
        import(/* webpackChunkName: "artists" */ "../vue/Artists.vue"),
      awardsrecipients: () =>
        import(
          /* webpackChunkName: "recipients" */ "../vue/Awards/Recipients.vue"
        ),
      filteritem: () =>
        import(
          /* webpackChunkName: "filteritem" */ "../vue/Artists/FilterItem.vue"
        ),
      milestoneslider: () =>
        import(
          /* webpackChunkName: "milestoneslider" */ "../vue/Artists/MilestoneSlider.vue"
        ),
      boostedslider: () =>
        import(
          /* webpackChunkName: "boostedslider" */ "../vue/Home/BoostedSlider.vue"
        ),
      slidersecondary: () =>
        import(
          /* webpackChunkName: "slidersecondary" */ "../vue/Home/SliderSecondary.vue"
        ),
      galleryslider: () =>
        import(
          /* webpackChunkName: "galleryslider" */ "../vue/Gallery/GallerySlider.vue"
        ),
      modalgalleryslider: () =>
        import(
          /* webpackChunkName: "modalgalleryslider" */ "../vue/ModalGallerySlider/ModalGallerySlider.vue"
        ),
      modalgalleryslidervideo: () =>
        import(
          /* webpackChunkName: "modalgalleryslidervideo" */ "../vue/ModalGallerySlider/ModalGallerySliderVideo.vue"
        ),
      spheres: () =>
        import(/* webpackChunkName: "slidersecondary" */ "../vue/Spheres.vue"),
      socialfeed: () =>
        import(/* webpackChunkName: "socialfeed" */ "../vue/SocialFeed.vue"),
      socialfeedlist: () =>
        import(
          /* webpackChunkName: "socialfeedlist" */ "../vue/SocialFeedList.vue"
        ),
      artcanvas: () =>
        import(/* webpackChunkName: "artcanvas" */ "../vue/ArtCanvas.vue"),
      creativityiscanvas: () =>
        import(
          /* webpackChunkName: "creativityiscanvas" */ "../vue/CreativityIsCanvas.vue"
        ),
      artmodal: () =>
        import(/* webpackChunkName: "artmodal" */ "../vue/ArtModal.vue"),
      artlist: () =>
        import(/* webpackChunkName: "artlist" */ "../vue/ArtList.vue"),
      events: () =>
        import(/* webpackChunkName: "events" */ "../vue/Events.vue"),
      scrollarrow: () =>
        import(/* webpackChunkName: "scrollarrow" */ "../vue/ScrollArrow.vue"),
      canvasscrollarrow: () =>
        import(
          /* webpackChunkName: "canvasscrollarrow" */ "../vue/CanvasScrollArrow.vue"
        ),
      featuredtalks: () =>
        import(
          /* webpackChunkName: "featuredtalks" */ "../vue/FeaturedTalks.vue"
        ),
    },
    data() {
      return {
        threeLoaded: false,
        modalIsOpen: false,
        videoId: null,
        galleryId: null,
        popupIsHidden: true,
        windowHeight: null,
        hasScrolledWindowHeight: false,
      };
    },
    methods: {
      setRatio(width, height) {
        return `padding-bottom: ${(height / width) * 100}%`;
      },
      showVideoModal(id) {
        this.modalIsOpen = true;
        this.videoId = id;
        document.body.classList.add("no-scroll");
      },
      showGalleryModal(id) {
        this.modalIsOpen = true;
        this.galleryId = id;
        document.body.classList.add("no-scroll");
      },
      hideModal() {
        this.modalIsOpen = false;
        this.galleryId = null;
        this.videoId = null;
        document.body.classList.remove("no-scroll");
      },
      hidePopup() {
        this.popupIsHidden = true;
        const today = new Date();
        const expire = new Date();
        expire.setTime(today.getTime() + 3600000 * 24 * 30);

        document.cookie = `popupIsHidden=true; expires=${expire.toGMTString()}; path=/`;
      },
      handlePopupReveal() {
        let ticking = false;

        if (!ticking) {
          window.requestAnimationFrame(() => {
            if (
              window.pageYOffset >= this.windowHeight &&
              !this.hasScrolledWindowHeight
            ) {
              this.hasScrolledWindowHeight = true;
            }
            ticking = false;
          });

          ticking = true;
        }
      },
    },
    mounted() {
      window.app.emit("vue-mounted");
      if (
        !document.cookie
          .split(";")
          .some((item) => item.includes("popupIsHidden=true"))
      ) {
        this.popupIsHidden = false;
        this.windowHeight = window.innerHeight;
        document.addEventListener("scroll", this.handlePopupReveal);
        this.handlePopupReveal();
      }
      let self = this;
      THREE.DefaultLoadingManager.onLoad = function() {
        self.threeLoaded = !self.threeLoaded;
      };

      var animate = window.requestAnimationFrame;
      var lastPosition = -1;
      var hamburgerColorBlack = false;
      var hamburgerColorWhite = false;

      function scroll() {
        var sections = document.querySelectorAll(".js-section");
        var hamburger = document.querySelectorAll(".js-hamburger");
        var hamburgerTop = hamburger[0].getBoundingClientRect().top;
        var hamburgerHeight = hamburger[0].getBoundingClientRect().height;

        var sectionTop = -1;
        var sectionBottom = -1;

        // fire on scroll
        if (lastPosition == window.pageYOffset) {
          animate(scroll);
          return false;
        } else {
          lastPosition = window.pageYOffset;

          Array.prototype.forEach.call(sections, function(el, i) {
            sectionTop = parseInt(
              el.getBoundingClientRect().top -
                (hamburgerHeight / 2 + hamburgerTop)
            );
            sectionBottom = parseInt(
              el.getBoundingClientRect().bottom -
                (hamburgerHeight / 2 + hamburgerTop)
            );

            if (sectionTop < 0 && sectionBottom > 0) {
              if (el.dataset.hamburgerColor === "white") {
                if (!hamburgerColorWhite) {
                  hamburgerColorBlack = false;
                  hamburgerColorWhite = true;
                  document.documentElement.style.setProperty(
                    "--hamburger-color",
                    "white"
                  );
                }
              } else {
                if (!hamburgerColorBlack) {
                  hamburgerColorBlack = true;
                  hamburgerColorWhite = false;
                  document.documentElement.style.setProperty(
                    "--hamburger-color",
                    "black"
                  );
                }
              }
            }
          });
        }
        animate(scroll);
      }

      scroll();
      window.onresize = function(event) {
        this.windowHeight = window.innerHeight;
        scroll();
      };
    },
  });
};
// Execute async function
main().then((value) => {});
